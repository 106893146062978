<template>
  <div>
    <van-cell-group title="筛选">
      <van-dropdown-menu>
        <van-dropdown-item v-model="sea.TYPE" @change="filter" :options="[
            {text:'全部类型单据', value:0},
            {text:'领用单', value:1},
            {text:'采购单', value:2},
            {text:'盘存单', value:3}
        ]" />
      </van-dropdown-menu>
      <van-search v-model="sea.KEY" placeholder="请输入搜索关键字" @search="filter" @clear="filter" />
      <van-field readonly label="分类" placeholder="选择分类筛选" is-link @click="cat.show=true" v-model="cat.txt" />
      <van-popup v-model="cat.show" round position="bottom">
        <van-cascader title="请选择分类" :options="cat.ls" @finish="catSel" @close="cat.show=false" v-model="sea.CAT_ID"
          :field-names="{text:'NAME',value:'ID',children:'CHILDREN'}" />

      </van-popup>
      <van-cell title="日期" :value="date.val" @click="date.show=true" is-link />
      <van-calendar v-model="date.show" type="range" @confirm="dateSel" :allow-same-day="true"
        :min-date="date.minDate" />
    </van-cell-group>
    <van-divider />
    <van-empty description="暂无数据" v-if="lst.da.length==0" />
    <van-list :finished="lst.finish" @load="loadPage" v-model="lst.loading" style="padding: 10px 0px;">
      <div class="wcard" v-for="item in lst.da" :key="item.ID">
        <div class="bd">
          <van-row :gutter="8">
            <van-col :span="6" class="lf" :class="{'active':item.TYPE==1}">
              <h2>{{ item.CNTP }}</h2>
              <span>{{ item.UNIT }}</span>
              <p>{{ item.HANDLE_NAME }}</p>
            </van-col>
            <van-col :span="18">
              <p>{{ item.ST_NAME }}</p>
              <p style="font-size: 14px; color: #999">{{item.DESCRIPTION}}</p>
              <p v-if="item.TYPE==1" style="font-size: 14px; color: #999">归属：{{item.CLASS_NAME||item.LOCA}}</p>
              <p v-if="item.TYPE==2" style="font-size: 14px; color: #999">单价：{{item.PRICE}}元</p>
            </van-col>
          </van-row>
        </div>
        <div class="ft">
          <van-row>
            <van-col :span="8">{{item.TYPE_TXT}}</van-col>
            <van-col :span="8">{{item.EVENT_DT|datetime('YYYY-MM-DD')}}</van-col>
            <van-col :span="8">{{item.CAT_NAME}}</van-col>
          </van-row>
        </div>
      </div>
    </van-list>
  </div>
</template>
<style scoped>
  .wcard {
    margin: 0 12px 12px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 4px rgba(0, 0, 0, .1)
  }

  .wcard .ft {
    border-top: 1px dashed #ebedf0;
    padding: 8px 16px;
    font-size: 12px;
    min-height: 20px
  }

  .wcard .bd {}

  .wcard .bd .lf {
    text-align: center;
    color: #ee0a24;
    padding-top: 10px
  }

  .wcard .bd .active {
    text-align: center;
    color: #07c160;
    padding-top: 10px
  }

  .wcard .bd .lf h2 {
    font-size: 30px;
    font-weight: 500;
    display: inline;
  }

  .wcard .bd .lf span {
    font-size: 40%;
    font-weight: normal;
    margin-left: 2px
  }

  .wcard .bd .lf p {
    font-size: 14px;
    line-height: 16px
  }
</style>
<script>
  import moment from "moment";
  export default {
    name: "index",
    data() {
      return {
        sea: {
          PAGE_SIZE: 10,
          PAGE_INDEX: -1,
          KEY: "",
          CAT_ID: 0,
          TYPE: 0
        },
        lst: {
          da: [],
          loading: false,
          finish: false
        },
        cat: {
          show: false,
          ls: [],
          txt: ""
        },
        date: {
          show: false,
          val: "",
          minDate: moment('2021-07-01').toDate()
        }
      }
    },
    created() {
      this.getCat();
    },
    methods: {
      getCat() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/Mobile/MAT/MCatApi/GetTree",
          completed: function (its) {
            self.cat.ls = its.DATA;
          }
        })
      },
      filter() {
        this.cat.show = false;
        this.lst.da.length = 0;
        this.lst.finish = false;
        this.sea.PAGE_INDEX = 0;
        this.getList();
      },
      loadPage() {
        this.sea.PAGE_INDEX += 1;
        this.getList();
      },
      getList() {
        let self = this;
        this.whale.remote.getCollection({
          url: "/api/Mobile/MAT/MBillApi/GetList",
          data: this.sea,
          completed(its, n) {
            self.lst.da.push(...its.ITEMS);
            self.lst.loading = false;
            if (self.lst.da.length >= n) self.lst.finish = true;
          }
        })
      },
      catSel({ selectedOptions }) {
        this.cat.show = false;
        this.cat.txt = selectedOptions.map((option) => option.NAME).join('/');
        this.filter();
      },
      dateSel(d) {
        this.sea.DTS = moment(d[0]).format('YYYY-MM-DD');
        this.sea.DTE = moment(d[1]).format('YYYY-MM-DD')
        this.date.show = false;
        this.date.val = this.sea.DTS + ' 至 ' + this.sea.DTE;
        this.filter();
      }
    }
  }
</script>